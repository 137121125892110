import React, { useState, useEffect, createContext } from 'react';
import { apiBaseURL, apiKey, apiURL, BOOK_VARIENTS } from './constant';
import { useSearchParams } from 'react-router-dom';

export const Contaxt = createContext();

export default function Store({ children }) {
  const [initializing, setInitialize] = useState(true);

  // Global state variables
  const [menuItems, setMenuItem] = useState([]);
  const [marquee, setMarquee] = useState("");
  const [marqueeSlug, setMarqueeSlug] = useState("");
  const [bannerItems, setBannerItems] = useState({ items: [], isLoading: true });
  const [centermenu, setCentermenu] = useState([]);
  const [homePopup, setHomePopup] = useState([]);

  const [newreleaseItems, setNewreleaseItems] = useState({ items: [], isLoading: true });
  const [recommendedbooksItems, setRecommendedbooksItems] = useState({ items: [], isLoading: true });
  const [bestSellersItems, setBestSellersItems] = useState({ items: [], isLoading: true });
  const [booklunchItems, setBooklunchItems] = useState({ items: [], isLoading: true });
  const [categoryItems, setCategoryItems] = useState([]);
  const [aboutLine, setAboutLine] = useState("");
  const [ourAuthor, setOurAuthor] = useState([]);
  const [cartItmeNum, setCartItemNum] = useState(0);

  const [homePageSeo, setHomePageSeo] = useState({
    title: "RSPL",
    description: "",
    keywords: "",
    ogTags: {},
    twitterTags: {}
  });

  const [booksPageSeo, setBookPageSeo] = useState({
    title: "RSPL",
    description: "",
    keywords: "",
    ogTags: {},
    twitterTags: {}
  });

  const [offlineCartData, setOfflineCartData] = useState({
    items: [],
    totalMrp: 0,
    totalDiscount: 0,
    totalAfterDiscount: 0,
    shippingCharge: 0,
    grandTotal: 0
  });

  const [userData, setUserData] = useState({
    loginStatus: false,
    name: "",
    email: "",
    address: "",
    pin: "",
    city: "",
    country: "",
    state: "",
    landmark: "",
    mobile: "",
    userType: "",
    token: "",
    isLoading: false,
    error: { status: false, msg: "" }
  });

  const [queryString] = useSearchParams();

  const [footerData, setFooterData] = useState({
    productServices: [],
    termUses: [],
    loginData: [],
    socialItems: []
  });

  // On component mount, load initial data, center data and footer data
  useEffect(() => {
    // Inline async function for initialization
    const init = async () => {
      try {
        await Promise.all([
          getInitialData(),
          checkUserLogin(),
          getHomePageSeo(),
          getBooksPageSeo()
        ]);
      } catch (err) {
        console.error(err);
      } finally {
        setInitialize(false);
      }
    };

    init();
    getCenterData();
    getFooterData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // ---------------- Utility Functions ----------------

  const resetUser = () => {
    setUserData({
      loginStatus: false,
      name: "",
      email: "",
      address: "",
      pin: "",
      city: "",
      country: "",
      state: "",
      mobile: "",
      landmark: "",
      userType: "",
      token: "",
      isLoading: false,
      error: { status: false, msg: "" }
    });
    localStorage.removeItem('token');
  };

  const resetOfflineCartData = () => {
    setOfflineCartData({
      items: [],
      totalMrp: 0,
      totalDiscount: 0,
      totalAfterDiscount: 0,
      shippingCharge: 0,
      grandTotal: 0
    });
    localStorage.removeItem('cartData');
  };

  // ---------------- API Calls ----------------

  const getInitialData = async () => {
    try {
      const response = await fetch(apiBaseURL + apiURL.initialData, {
        method: 'GET',
        headers: { 'rskey': apiKey },
      });
      const result = await response.json();
      if (result.status === "1") {
        setMenuItem(result.sections?.menu);
        setMarquee(result.sections?.header_marquee?.maruee_title);
        setMarqueeSlug(result.sections?.header_marquee?.slug);
        setCentermenu(result.sections?.center_menus || []);
        setBannerItems({ items: result.sections?.banners, isLoading: false });
        setHomePopup(result.sections?.home_popup || []);
      } else {
        console.log(result.message);
        setBannerItems({ items: [], isLoading: false });
      }
    } catch (error) {
      console.error(error);
      setBannerItems({ items: [], isLoading: false });
    }
  };

  const getHomePageSeo = async () => {
    try {
      const response = await fetch(apiBaseURL + apiURL.homeSeo, {
        method: 'POST',
        headers: { 'rskey': apiKey },
        body: JSON.stringify({ "seo": "homepage" })
      });
      const result = await response.json();
      if (result.status === "1") {
        setHomePageSeo({
          title: result.Meta_Title,
          description: result.Meta_Description,
          keywords: result.Meta_Keyword,
          ogTags: result.Og_Tag,
          twitterTags: result.Twitter_Tag
        });
      } else {
        console.log(result.message);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const getBooksPageSeo = async () => {
    try {
      const response = await fetch(apiBaseURL + apiURL.booksSeo, {
        method: 'POST',
        headers: { 'rskey': apiKey },
        body: JSON.stringify({ "seo": "books" })
      });
      const result = await response.json();
      if (result.status === "1") {
        setBookPageSeo({
          title: result.Meta_Title,
          description: result.Meta_Description,
          keywords: result.Meta_Keyword,
          ogTags: result.Og_Tag,
          twitterTags: result.Twitter_Tag
        });
      } else {
        console.log(result.message);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const getCenterData = async () => {
    try {
      const response = await fetch(apiBaseURL + apiURL.middleData, {
        method: 'GET',
        headers: { 'rskey': apiKey },
      });
      const result = await response.json();
      if (result.status === "1") {
        setNewreleaseItems({ items: result.sections?.new_relases, isLoading: false });
        setRecommendedbooksItems({ items: result.sections?.recommended_books, isLoading: false });
        setBestSellersItems({ items: result.sections?.best_sellers, isLoading: false });
        setBooklunchItems({ items: result.sections?.book_lunch, isLoading: false });
        setCategoryItems(result.sections?.category);
        setAboutLine(result.sections?.about_us?.[0]?.desp);
        setOurAuthor(result.sections?.authors || []);
      } else {
        console.log(result.message);
        setNewreleaseItems({ items: [], isLoading: false });
        setRecommendedbooksItems({ items: [], isLoading: false });
        setBestSellersItems({ items: [], isLoading: false });
        setBooklunchItems({ items: [], isLoading: false });
      }
    } catch (error) {
      console.error(error);
      setNewreleaseItems({ items: [], isLoading: false });
      setRecommendedbooksItems({ items: [], isLoading: false });
      setBestSellersItems({ items: [], isLoading: false });
      setBooklunchItems({ items: [], isLoading: false });
    }
  };

  const checkUserLogin = async (sendToken = null) => {
    let token = sendToken !== null ? sendToken : localStorage.getItem('token');
    if (!token && queryString.get('ta') !== null) {
      token = queryString.get('ta');
      localStorage.setItem("token", token);
    }
    if (token !== null) {
      try {
        const result = await fetch(apiBaseURL + apiURL.userProfile, {
          method: 'POST',
          headers: {
            'rskey': apiKey,
            'utoken': token
          },
        });
        const res = await result.json();
        if (res.status === "1") {
          setUserData({
            name: res.user_data.name,
            email: res.user_data.email,
            address: res.user_data.address,
            pin: res.user_data.Zip,
            city: res.user_data.City,
            country: res.user_data.Country,
            state: res.user_data.State,
            landmark: res.user_data.landmark,
            mobile: res.user_data.Mobile,
            userType: res.user_data.user_type,
            token: token,
            isLoading: false,
            loginStatus: true,
            error: { status: false, msg: "" }
          });
          localStorage.removeItem('cartData');
          setCartItemNum(res.Total_Items);
        } else {
          localStorage.removeItem('token');
        }
      } catch (error) {
        console.error(error);
        localStorage.removeItem('token');
        const cartData = localStorage.getItem('cartData');
        if (cartData !== null) {
          const jsonData = JSON.parse(cartData);
          setOfflineCartData(jsonData);
          setCartItemNum(jsonData.items.length);
        }
      }
    } else {
      const cartData = localStorage.getItem('cartData');
      if (cartData !== null) {
        const jsonData = JSON.parse(cartData);
        setOfflineCartData(jsonData);
        setCartItemNum(jsonData.items.length);
      }
      return false;
    }
  };

  // ---------------- User Data Management ----------------

  const changeUserData = (data) => {
    setUserData((prev) => ({ ...prev, ...data }));
  };

  const userLogin = (data) => {
    setUserData((prev) => ({
      ...prev,
      isLoading: true,
      error: { status: false, msg: "" }
    }));
    const cartData = offlineCartData.items.map(item => ({
      "bookId": item.ProductId,
      "bookType": item.Product_Type,
      "quantity": item.Quantity
    }));
    const sendData = { ...data, "cart_items": cartData };
    fetch(apiBaseURL + apiURL.login, {
      method: 'POST',
      headers: { 'rskey': apiKey },
      body: JSON.stringify(sendData)
    })
      .then(result => result.json())
      .then(res => {
        if (res.status === "1") {
          setUserData({
            name: res.data.name,
            email: res.data.email,
            address: res.data.address,
            pin: res.data.Zip,
            city: res.data.City,
            country: res.data.Country,
            state: res.data.State,
            landmark: res.data.landmark,
            mobile: res.data.Mobile,
            userType: res.data.user_type,
            token: res.data.api_token,
            isLoading: false,
            loginStatus: true,
            error: { status: false, msg: "" }
          });
          localStorage.setItem('token', res.data.api_token);
          resetOfflineCartData();
          setCartItemNum(res.Total_Items);
        } else {
          setUserData({
            name: "",
            email: "",
            token: "",
            isLoading: false,
            loginStatus: false,
            error: { status: true, msg: res.message }
          });
          localStorage.removeItem('token');
        }
      })
      .catch(err => {
        console.error(err);
        setUserData({
          name: "",
          email: "",
          token: "",
          isLoading: false,
          loginStatus: false,
          error: { status: true, msg: "Network Error!" }
        });
        localStorage.clear();
      });
  };

  const userLogout = () => {
    const token = localStorage.getItem('token');
    setUserData((prev) => ({ ...prev, isLoading: true }));
    if (token !== null) {
      fetch(apiBaseURL + apiURL.logout, {
        method: 'POST',
        headers: {
          'rskey': apiKey,
          'utoken': token,
        }
      })
        .then(result => result.json())
        .then(res => {
          resetUser();
          localStorage.clear();
          setCartItemNum(0);
        })
        .catch(err => {
          console.error(err);
          localStorage.clear();
          setCartItemNum(0);
          resetUser();
        });
    } else {
      resetUser();
    }
  };

  // ---------------- Cart Management ----------------

  const getChoseItemData = (item, type) => {
    const newItem = {
      mrp: "",
      discount: "",
      price: "",
      productId: item.productId,
      type: type,
      title: item.product_title,
      typeTitle: "",
      image: item.front_image,
      isbn: item.isbn,
      class: item.class_title,
      subject: item.subject_title,
      disPercent: 0,
    };

    if (type === BOOK_VARIENTS.pBook) {
      newItem.mrp = item.book_mrp;
      newItem.discount = item.book_discount;
      newItem.price = item.book_price;
      newItem.typeTitle = item.paperbook_type_title;
      newItem.disPercent = item.book_perDiscount;
    } else if (type === BOOK_VARIENTS.eBook) {
      newItem.mrp = item.ebook_mrp;
      newItem.discount = item.eBook_discount;
      newItem.price = item.ebook_price;
      newItem.typeTitle = item.eBook_type_title;
      newItem.disPercent = item.ebook_perDiscount;
    } else if (type === BOOK_VARIENTS.ieBook) {
      newItem.mrp = item.interactiveEbook_mrp;
      newItem.discount = item.interactiveEbook_discount;
      newItem.price = item.interactiveEbook_price;
      newItem.typeTitle = item.InteractiveEbook_type_title;
      newItem.disPercent = item.interactiveEbook_perDiscount;
    } else if (type === BOOK_VARIENTS.cdDisk) {
      newItem.mrp = item.cd_mrp;
      newItem.discount = item.cd_discount;
      newItem.price = item.cd_price;
      newItem.typeTitle = item.cd_book_type_title;
      newItem.disPercent = item.cd_perDiscount;
    } else if (type === BOOK_VARIENTS.gBook) {
      newItem.mrp = item.games_mrp;
      newItem.discount = item.games_discount;
      newItem.price = item.games_price;
      newItem.typeTitle = item.game_type_title;
      newItem.disPercent = item.games_perDiscount;
    } else if (type === BOOK_VARIENTS.peBook) {
      newItem.mrp = item.paperbook_ebook_mrp;
      newItem.discount = item.paperbook_ebook_discount;
      newItem.price = item.paperbook_ebook_price;
      newItem.typeTitle = item.paperbook_ebook_type_title;
      newItem.disPercent = item.paperbook_ebook_perDiscount;
    }
    return newItem;
  };

  const getCartCalculation = (items) => {
    let totalMrp = 0, totalDiscount = 0, totalAfterDiscount = 0, shippingCharge = 0, grandTotal = 0;
    for (let item of items) {
      totalMrp += item.Product_MRP * item.Quantity;
      totalDiscount += item.Base_Discount * item.Quantity;
    }
    totalDiscount = Math.round(totalDiscount * 100) / 100;
    totalMrp = Math.round(totalMrp * 100) / 100;
    totalAfterDiscount = totalMrp - totalDiscount;
    grandTotal = totalAfterDiscount - shippingCharge;
    return { totalMrp, totalDiscount, totalAfterDiscount, shippingCharge, grandTotal };
  };

  const getItemIndex = (bookId, type, items) => {
    for (let [ind, item] of items.entries()) {
      if (item.ProductId === bookId && item.Product_Type === type) {
        return ind;
      }
    }
    return -1;
  };

  const addItemOnCart = (item, type) => {
    setOfflineCartData((prev) => {
      const items = [...prev.items];
      const newItem = getChoseItemData(item, type);
      const adItem = {
        "Product_MRP": newItem.mrp,
        "Total_MRP": newItem.mrp,
        "Base_Discount": newItem.discount,
        "Total_Discount": newItem.discount,
        "Base_Price": newItem.price,
        "Total_Price": newItem.price,
        "Quantity": 1,
        "ProductId": newItem.productId,
        "Product_Type": newItem.type,
        "Product_Type_Title": newItem.typeTitle,
        "Product_Discount_Percent": newItem.disPercent,
        "Product_Title": newItem.title,
        "Product_Class": newItem.class,
        "Product_Subject": newItem.subject,
        "Product_ISBN": newItem.isbn,
        "Image": newItem.image
      };
      items.push(adItem);
      const remainingData = getCartCalculation(items);
      const newData = { ...prev, items, ...remainingData };
      localStorage.setItem('cartData', JSON.stringify(newData));
      setCartItemNum(items.length);
      return newData;
    });
  };

  const updateItemOnCart = (bookId, type, action) => {
    setOfflineCartData((prev) => {
      const items = [...prev.items];
      const index = getItemIndex(bookId, type, items);
      if (index !== -1) {
        if (action === "add") {
          items[index].Quantity++;
          items[index].Total_MRP = items[index].Product_MRP * items[index].Quantity;
          items[index].Total_Price = Math.round(Number(items[index].Base_Price) * items[index].Quantity * 100) / 100;
          items[index].Total_Discount = Math.round(Number(items[index].Base_Discount) * items[index].Quantity * 100) / 100;
        } else if (action === "remove" && items[index].Quantity > 1) {
          items[index].Quantity--;
          items[index].Total_MRP = items[index].Product_MRP * items[index].Quantity;
          items[index].Total_Price = Math.round(Number(items[index].Base_Price) * items[index].Quantity * 100) / 100;
          items[index].Total_Discount = Math.round(Number(items[index].Base_Discount) * items[index].Quantity * 100) / 100;
        }
      }
      const remainingData = getCartCalculation(items);
      const newData = { ...prev, items, ...remainingData };
      localStorage.setItem('cartData', JSON.stringify(newData));
      setCartItemNum(items.length);
      return newData;
    });
  };

  const removeItemFromCart = (bookId, type) => {
    setOfflineCartData((prev) => {
      const items = [...prev.items];
      const index = getItemIndex(bookId, type, items);
      if (index !== -1) {
        items.splice(index, 1);
      }
      const remainingData = getCartCalculation(items);
      const newData = { ...prev, items, ...remainingData };
      localStorage.setItem('cartData', JSON.stringify(newData));
      setCartItemNum(items.length);
      return newData;
    });
  };

  // ---------------- Footer Data ----------------

  const getFooterData = () => {
    const urls = [
      apiBaseURL + apiURL.footerProductService,
      apiBaseURL + apiURL.footerTermsofuse,
      apiBaseURL + apiURL.footerLogin
    ];
    Promise.all(urls.map(url => fetch(url, { headers: { 'rskey': apiKey } })))
      .then(responses => Promise.all(responses.map(res => res.json())))
      .then(results => {
        let productServices = [];
        let termUses = [];
        let loginData = [];
        let socialItems = [];
        if (results[0].status === "1") {
          productServices = results[0].footer_product_services;
          socialItems = results[0].social_icons;
        }
        if (results[1].status === "1") {
          termUses = results[1].footer_terms_of_use;
        }
        if (results[2].status === "1") {
          loginData = results[2].footer_login;
        }
        setFooterData({ productServices, termUses, loginData, socialItems });
      })
      .catch(err => console.error(err));
  };

  // ---------------- Render Provider ----------------

  return (
    <Contaxt.Provider
      value={{
        initializing,
        menuItems,
        marquee,
        marqueeSlug,
        bannerItems,
        centermenu,
        homePopup,
        userData,
        userLogin,
        userLogout,
        checkUserLogin,
        changeUserData,
        resetUser,
        offlineCartData,
        addItemOnCart,
        updateItemOnCart,
        removeItemFromCart,
        cartItmeNum,
        setCartItemNum,
        newreleaseItems,
        recommendedbooksItems,
        bestSellersItems,
        booklunchItems,
        categoryItems,
        aboutLine,
        ourAuthor,
        footerData,
        homePageSeo,
        booksPageSeo,
      }}
    >
      {children}
    </Contaxt.Provider>
  );
}
