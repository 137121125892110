import React, { useEffect, useContext, Suspense } from "react";
import { Routes, Route, useLocation } from "react-router-dom";
import { Helmet } from "react-helmet";
import "./index.css";
import "./Component/Front/css/home.css";
import "./Component/Front/css/inner-page.css";
import "./Component/Front/css/index-resonsive.css";
import "./Component/Front/css/inner-responsive.css";
import "bootstrap/dist/css/bootstrap.min.css";
import Loader from "./Loader";
import { Contaxt } from "./Store";
//
// Lazy-load route components for code splitting
//
const Home = React.lazy(() => import('./Component/Home'));
const About_us = React.lazy(() => import('./Component/Front/Layout/About_us'));
const Login = React.lazy(() => import('./Component/Front/Auth/Login'));
const GoogleRedirect = React.lazy(() => import('./Component/Front/Auth/GoogleRediect'));
const Sign_up = React.lazy(() => import('./Component/Front/Auth/Sign_up'));
const Forget_passward = React.lazy(() => import('./Component/Front/Auth/Forget_passward'));
const ResetPassword = React.lazy(() => import('./Component/Front/Auth/ResetPassword'));
const RequestOtp = React.lazy(() => import('./Component/Front/RequsetOtp/RequsetOtp'));
const Become_distributor = React.lazy(() => import('./Component/Front/Distributor/Become_distributor'));
const Feedback = React.lazy(() => import('./Component/Front/Feedback/Feedback'));
const Useraccount = React.lazy(() => import('./Component/Front/Profile/Useraccount'));
const UserAddress = React.lazy(() => import('./Component/Front/Profile/UserAddress'));
const UserOrder = React.lazy(() => import('./Component/Front/Profile/UserOrder'));
const RewardPoint = React.lazy(() => import('./Component/Front/Profile/RewardPoint'));
const Profile = React.lazy(() => import('./Component/Front/Profile/Profile'));
const Contactus = React.lazy(() => import('./Component/Front/Contactus/Contactus'));
const Book = React.lazy(() => import('./Component/Front/Book/books'));
const NewRelease = React.lazy(() => import('./Component/Front/NewReleases/NewReleases'));
const Books = React.lazy(() => import("./Component/Front/Cbse/Books"));
const Products = React.lazy(() => import('./Component/Front/Bookdetails/products'));
const OrderDetail = React.lazy(() => import('./Component/Front/OrderDetail/Orderdetails'));
const Order = React.lazy(() => import('./Component/Front/Order/Order'));
const Payment = React.lazy(() => import("./Component/Front/Payment/Payment"));
const WriteReview = React.lazy(() => import('./Component/Front/WriteReview/WriteReview'));
const EditWriteReview = React.lazy(() => import('./Component/Front/WriteReview/EditWriteReview'));
const CustomerReview = React.lazy(() => import("./Component/Front/CustomerReview/CustomerReview"));
const Thankyou = React.lazy(() => import('./Component/Front/Thanku/Thankyou'));
const TrackingDetail = React.lazy(() => import('./Component/Front/Tracking/TrackingDetails'));
const Career = React.lazy(() => import('./Component/Front/Career/Career'));
const Careerdetail = React.lazy(() => import('./Component/Front/CareerDetail/CareerDetail'));
const ShareCv = React.lazy(() => import('./Component/Front/ShareCareer/ShareCv'));
const Bookshop = React.lazy(() => import("./Component/Front/BookStore/Bookshop"));
const TermCondition = React.lazy(() => import('./Component/Front/TermsConditions/TermCondition'));
const CanclePolicy = React.lazy(() => import('./Component/Front/Cancellati_Policy/Policy'));
const PrivacyPolicy = React.lazy(() => import('./Component/Front/PrivacyPolicy/PrivacyPolicy'));
const Disclaimer = React.lazy(() => import('./Component/Front/Disclaimer/Disclaimer'));
const Faqs = React.lazy(() => import('./Component/Front/Faq/Faqs'));
const BecomeDistributor = React.lazy(() => import("./Component/Front/Distributor/Become_distributor"));
const Catalogue = React.lazy(() => import('./Component/Front/ProductCatalogue/Catalogue'));
const Specimen = React.lazy(() => import('./Component/Front/Specimen/Specimen'));
const RenowedAuthors = React.lazy(() => import('./Component/Front/RenownedAuthors/Authors'));
const InvitionAuthors = React.lazy(() => import('./Component/Front/InvitationAuthor/Invitation_Author'));
const NewsEvent = React.lazy(() => import('./Component/Front/NewsEvent/NewsEvents'));
const NewsDetail = React.lazy(() => import('./Component/Front/NewsDetali/NewsEventsDetali'));
const SiteMap = React.lazy(() => import('./Component/Front/SiteMap/SiteMap'));
const PageNotFound = React.lazy(() => import('./Component/Front/PageNotFound/PageNotFound'));
const Wishlist = React.lazy(() => import('./Component/Front/Wishlist/Wishlist'));

const Reorder = React.lazy(() => import('./Component/Front/Reorder/Reorder'));

const OrderFrom = React.lazy(() => import("./Component/Front/OrderFrom/OrderFrom"));
const TogetherFrom = React.lazy(() => import("./Component/Front/OrderFrom/TogetherFrom"));
const PrimaryFrom = React.lazy(() => import("./Component/Front/OrderFrom/PrimaryFrom"));
const BuyNow = React.lazy(() => import('./Component/Front/BuyNow/BuyNow'));
const AwardDetail = React.lazy(() => import('./Component/Front/AwardDetail/AwardDetail'));
const ViewOrder = React.lazy(() => import("./Component/Front/Profile/ViewOrder"));
const Ordertest = React.lazy(() => import("./Component/Front/Wishlist/Ordertest"));
const LivingChange = React.lazy(() => import('./Component/Front/LivingChange/LivingChange'));
const LivingDetail = React.lazy(() => import('./Component/Front/LivingChange/LivingDetail'));
const PageDetails = React.lazy(() => import('./Component/Front/DynamicPage/PageDetails'));


const App = () => {
  const currentUrl = window.location.href;
  const location = useLocation();
  const { initializing } = useContext(Contaxt);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  if (initializing) {
    return <Loader />;
  }

  return (
    <Suspense fallback={<Loader />}>
      <div>
        <Helmet>
          <link rel="canonical" href={currentUrl} />
          <script async src="https://www.googletagmanager.com/gtag/js?id=G-FRWKGMKTME"></script>
          <script
            dangerouslySetInnerHTML={{
              __html: `
                window.dataLayer = window.dataLayer || [];
                function gtag(){dataLayer.push(arguments);}
                gtag('js', new Date());
                gtag('config', 'G-FRWKGMKTME');
              `,
            }}
          />
        </Helmet>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/aboutus" element={<About_us />} />
          <Route path="/page-details/:slug" element={<PageDetails />} />

          <Route path="/account/login" element={<Login />}>
            <Route path=":rediUrl" element={<Login />} />
          </Route>
          <Route path="/googleLogin/:token" element={<GoogleRedirect />} />
          <Route path="/requestOtp" element={<RequestOtp />} />
          <Route path="/signup" element={<Sign_up />} />
          <Route path="/forget-passward" element={<Forget_passward />} />
          <Route path="/resetpassword" element={<ResetPassword />} />
          <Route path="/become-distributor" element={<Become_distributor />} />
          <Route path="/feedback" element={<Feedback />} />

          <Route path="/vieworder" element={<ViewOrder />} />

          <Route path="/profile" element={<Profile />}>
            <Route path="account" element={<Useraccount />} />
            <Route path="address" element={<UserAddress />} />
            <Route path="orders" element={<UserOrder />} />
            <Route path="rewardpoint" element={<RewardPoint />} />
            <Route path="writeReview/:bookId" element={<WriteReview />} />
            <Route path="EditWriteReview/:bookId" element={<EditWriteReview />} />
            <Route path="tracking/:orderId" element={<TrackingDetail />} />
            <Route path="payment" element={<Payment />} />
            <Route path="thankyou" element={<Thankyou />} />
            <Route path="wishlist" element={<Wishlist />} />
            <Route path="reorder" element={<Reorder />} />
            <Route path="buyNow" element={<BuyNow />} />
            <Route path="*" element={<PageNotFound />} />
          </Route>

          <Route path="/CustomerReview/:bookId" element={<CustomerReview />} />
          <Route path="/contactus" element={<Contactus />} />
          <Route path="/books" element={<Book />} />
          <Route path="/NewRelease" element={<NewRelease />} />

          <Route path="/books">
            <Route path=":boardId" element={<Books />} />
            <Route path=":boardId/:bookType" element={<Books />} />
            <Route path=":boardId/:classType/:bookType" element={<Books />} />
            <Route path=":boardId/:classType/:productType/:bookType" element={<Books />} />
          </Route>

          <Route path="/:products/:bookId" element={<Products />} />
          <Route path="/orderDetail" element={<OrderDetail />} />
          <Route path="/order" element={<Order />} />

          <Route path="/bookStoreLocator" element={<Bookshop />} />
          <Route path="/termCondition" element={<TermCondition />} />
          <Route path="/cancelPolicy" element={<CanclePolicy />} />
          <Route path="/privacyPolicy" element={<PrivacyPolicy />} />
          <Route path="/disclaimer" element={<Disclaimer />} />
          <Route path="/FAQ" element={<Faqs />} />

          <Route path="/living-for-change" element={<LivingChange />} />
          <Route path="/meera-balchandran" element={<LivingDetail />} />

          <Route path="/becomeDistributor" element={<BecomeDistributor />} />

          <Route path="/catalogue" element={<Catalogue />}>
            <Route path=":boardName" element={<Catalogue />} />
          </Route>
          <Route path="/specimen" element={<Specimen />} />
          <Route path="/renowedAuthors" element={<RenowedAuthors />} />
          <Route path="/invitionAuthors" element={<InvitionAuthors />} />

          <Route path="/career" element={<Career />} />
          <Route path="/careerdetail/:careerId" element={<Careerdetail />} />
          <Route path="/shareCV" element={<ShareCv />} />

          <Route path="/sitemap" element={<SiteMap />} />
          <Route path="/orderForm" element={<OrderFrom />} />
          <Route path="/togetherFrom" element={<TogetherFrom />} />
          <Route path="/primaryFrom" element={<PrimaryFrom />} />

          <Route path="/newsEvent" element={<NewsEvent />} />
          <Route path="/newsEventsDetail/:eventId" element={<NewsDetail />} />
          <Route path="/AwardDetail" element={<AwardDetail />} />
          <Route path="/ordertest" element={<Ordertest />} />

          <Route path="*" element={<PageNotFound />} />
        </Routes>
      </div>
    </Suspense>
  );
};

export default App;
