// export const apiBaseURL = "http://127.0.0.1:8000/api";
// export const baseURL = "http://127.0.0.1:8000";
export const apiBaseURL = "https://rachnasagar.in/api";
export const baseURL = "https://rachnasagar.in";
export const apiKey = "rspl";
export const BOOK_VARIENTS = Object.freeze({
    pBook: "p_book",
    eBook: "e_book",
    peBook: 'pe_book',
    ieBook: "ie_book",
    cdDisk: "cd_book",
    gBook: 'Games',

})
export const apiURL = Object.freeze({

    'bookDetails': '/bookDetails',
    'books': '/books/list',
    'subjects': '/subjects/list',
    'booktypes': '/booktypes/list',
    'classes': '/classes/list',
    'defaultBooks': '/books/defaultbooks',
    'bookDetail': '/books/bookdetail',
    'canWriteReview': '/reviews/canwritereview',
    'relatedBooks': '/books/get_related_books',

    'suggestedBooks': '/books/get_suggested_books',
    'frequentBook': '/books/get_frequent_books',


    'boostoreStates': '/bookstoreStateList',
    'boostoreCities': '/bookstoreCityList',
    'boostoreStores': '/bookstorelocator',

    'searchBooks': '/search',
    'subscribe': '/subscribe',

    'addToWishList': '/wishlist/add',
    'listWishlist': '/wishlist/list',

    'reorder': '/reorders',

    'rewardsList': '/rewards/list',

    'addToCartFBT': '/frequentAddToCart',
    'addRemoveFBT': '/frequentAddRemove',



    'oredrView': '/wish_list',

    'addToCartWishlist': '/wishlist/addToCart',
    'addToCart': '/cart/add',
    'deleteFromCart': '/cart/deleteProduct',
    'updateProduct': '/cart/updateProduct',
    'cartList': '/cart/list',
    'orderDetail': '/cart/orderDetails',
    'addNote': '/cart/addnote',
    'buyNowAddNote': '/cart/buynowaddnote',
    'rewardsManage': '/cart/rewardsManage',
    'checkout': '/checkout',
    'coupon': '/coupon',
    'buyNowCoupon': '/buynowcoupon',
    'myOrders': '/orders/myorders',
    'onlineOrder': '/order/place-order-online',
    'buyNowOnlineOrder': '/order/buynow-place-order-online',
    'offlineOrder': '/order/place-order-offline',


    'teacherschools': '/teacher_schools',
    'studentparents': '/student_parents',
    'booksellers': '/booksellers',
    'contactUs': '/contact-us',
    'countries': '/countries',
    'orderForm': '/orderForm',

    'newsevents': '/news_events',
    'newRelease': '/new-releases',
    // 'ourteam' : '/our-team',



    'awards': '/awards',
    // 'reasonfollow' : '/reason-to-follow',
    // 'associates' : '/our-associates',
    'authors': '/author',
    'aboutUsIcons': "/about-us/icons",

    // 'centerMenus':"/center-menus",
    'testimonials': "/testimonials",
    'category': "/category/list",
    'verifyCategory': "/category/verify-category",

    'newrelease': "/new-release",
    'initialData': '/home-top',
    'middleData': '/home-middle',
    'bottomData': '/home-bottom',
    'buyNow': '/buynow',
    // 'marquee' : "/header-marquee",
    // 'banners' : "/banners",
    // 'menu' : "/menu",

    'logout': "/user/logout",
    'login': "/user/login",
    'googleLogin': '/user/google-login',
    'requestOtp': '/user/request-otp',
    'varifyOtp': '/user/verify-otp',
    'signup': "/user/register",
    "userProfile": "/user/getUserProfile",
    "updateProfile": "/user/profile_update",
    "updateAddress": "/user/address_update",
    "updateShippingAddress": "/user/update_shipping_address",
    "addShippingAddress": "/user/add_new_shipping_address",
    "deleteShippingAddress": "/user/delete_shipping_address",
    "shippingAddressList": "/user/get_shipping_addresses",
    "setDefaultAddress": "/user/make_shipping_address_default",
    'forgotPassword': '/user/forgot-password',
    'sendOtpForForgotPassword': '/user/send_OtpForForgotPassword',
    'verifyOtpForForgotPassword': '/user/verify_OtpForForgotPassword',
    'resetPassword': '/user/set-forgot-password',

    'reviewStars': '/reviews/reviews_breakdown',
    "giveReview": "/reviews",
    "reviewList": "/reviews/list",

    'feedback': "/feedback",
    'togetherorder': "/togetherorder",
    'primaryorder': "/primaryorder",
    'distributors': "/distributors",

    'footerProductService': '/footer-product-service',
    'footerTermsofuse': '/footer-termsofuse',
    'footerLogin': '/footer-login',

    'homeSeo': '/seo/home',
    'aboutUsSeo': '/seo/aboutus',
    'booksSeo': '/seo/books',
    'boardSeo': '/seo/searchByBoard',
    'bookShopSeo': '/seo/bookshop',
    'becomeDistutorSeo': '/seo/becomeDistributor',
    'disclaimerSeo': '/seo/disclaimer',
    'feedbackSeo': '/seo/feedback',
    'faqSeo': '/seo/faqs',
    'privacyPolicySeo': '/seo/privacypolice',
    'careerSeo': '/seo/career',
    'careerDetailSeo': '/seo/careerDetails',
    'catalogueSeo': '/seo/catalogue',
    'termsConditionSeo': '/seo/termsConditions',
    'livingForChangeSeo': '/seo/livingForChange',

    'cancellationPolicySeo': '/seo/cancellationRefundPolicy',
    'specimenSeo': '/seo/specimen',
    'renownedAuthorsSeo': '/seo/ourRenownedAuthors',
    'invitionAuthorsSeo': '/seo/invitationToAuthors',
    'orderDetailsSeo': '/seo/orderDetails',
    'orderSeo': '/seo/order',
    'thankyouSeo': '/seo/thankyou',

    'vieworderFmail': '/vieworderFmail',

    'searchByBoardNcertSeo': '/seo/searchByBoard',
    

    'invitationAuthor': '/invitation-to-authors',
    'specimen': '/specimen',
    'speciCountries': '/countries-list',
    'speciStates': '/states-list',

    'careersList': '/careers/list',
    'careersDetail': '/careers/career_detail',
    'applyJob': '/careers/apply-job',

    'specimanSave': '/speciman-save',

    'recommendAFriend': '/careers/recommend-to-friend',
    'shareCV': '/careers/share_cv',

    'newsEventList': '/news_events/list',
    'newsEventDetail': '/news_events/detail',

    //'liveChat': '/livechat/chat',
    //'chatAnswer': 'livechat/questions',

    'addTogetherOrder': '/addTogetherOrder',
    'addPrduct': '/cart/productAdd',

    'catalogue': '/catalogue',
    'catalogueCategory': '/menu/category',
    'catalogueClass': '/menu/class',
    'catalogueBookType': '/menu/booktype',
    'catalogueSubject': '/menu/subject',
    'catalogueProduct': '/menu/products',
    'catalogueDefaultProducts': '/menu/defaultproducts',


});